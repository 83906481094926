<template>
  <div>
    <div class="header">
      <el-row type="flex" justify="space-between" :gutter="20">
        <el-col :span="8">
          <span class="label-sty">银行简称：</span>
          <el-input
            v-model.trim="bankName"
            placeholder="请输入"
            size="small"
          ></el-input>
        </el-col>
        <el-col :span="8">
          <span class="label-sty">银行编码：</span>
          <el-input
            v-model.trim="bankCode"
            placeholder="请输入"
            size="small"
          ></el-input>
        </el-col>

        <el-col :span="8">
          <div class="btn-sty">
            <el-button type="primary" size="small" @click="handleSearch"
              >查询</el-button
            >
            <el-button size="small" @click="handleClear">清空</el-button>
            <el-button
              type="primary"
              size="small"
              @click="handleAdd"
              v-perm="'/contactLine/add'"
              >新增支持银行</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <cesTable
      :isButton="true"
      :isSelection="false"
      :isIndex="true"
      :isPagination="true"
      :tableData="tableData"
      :tableCols="tableCols"
      :pagination="pagination"
      :loading="loading"
      @hadnleOperate="hadnleOperate"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    ></cesTable>

    <el-dialog
      :title="titleTxt"
      :visible.sync="dialogFormVisible"
      width="500px"
      :before-close="beforeClose"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        ref="dialogForm"
        :label-width="formLabelWidth"
      >
        <el-form-item label="银行简称" prop="bankName">
          <el-input v-model="dialogForm.bankName"></el-input>
        </el-form-item>
        <el-form-item label="银行编码" prop="bankCode">
          <el-input v-model="dialogForm.bankCode"></el-input>
        </el-form-item>
        <el-form-item label="银行行名" prop="branchName">
          <el-input v-model="dialogForm.branchName"></el-input>
        </el-form-item>
        <el-form-item label="银行行号" prop="branchCode">
          <el-input v-model="dialogForm.branchCode" maxlength="12"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogClose('dialogForm')">取 消</el-button>
        <el-button type="primary" @click="dialogSave('dialogForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- 启用/停用弹窗 -->
    <el-dialog
      title="支持银行状态"
      :visible.sync="dialogFormVisibleStatus"
      width="500px"
    >
      <div class="employee_box">
        <span>支持银行状态</span>
        <div class="switch-content">
          <el-switch v-model="employeeSwitch" @change="handleChange">
          </el-switch>
          <span
            class="state-text"
            :class="
              employeeSwitch === true ? 'on-text_color' : 'off-text_color'
            "
            >{{ employeeSwitch === true ? 'ON' : 'OFF' }}</span
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import cesTable from '@/components/table'
import {
  contactLineQuery,
  contactLineAdd,
  contactLineUpdate,
  contactLineEnableOrDisable,
} from '@/request/api'

export default {
  data() {
    return {
      bankName: '',
      bankCode: '',
      options: [],
      formLabelWidth: '80px',
      titleTxt: '',
      dialogFormVisible: false,
      dialogFormVisibleStatus: false,
      employeeSwitch: false,
      disabled: false,
      dialogForm: {
        bankName: '',
        bankCode: '',
        branchCode: '',
        branchName: '',
      },
      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: '操作',
          type: 'Button',
          width: '50',
          permission: '/contactLine/update',
          op: [
            { text: '编辑', class: 'handleModify' },
            { text: '开启/关闭', class: 'handleOp' },
          ],
        },
        { label: '银行简称', prop: 'bankName' },
        { label: '银行编码', prop: 'bankCode' },
        { label: '银行行名', prop: 'branchName' },
        { label: '银行行号', prop: 'branchCode', width: '180' },
        { label: '状态', prop: 'enabledDesc', width: '100' },
      ],
      rules: {
        bankCode: [{ required: true, message: '请输入', trigger: 'blur' }],
        bankName: [{ required: true, message: '请选择', trigger: 'blur' }],
        branchCode: [{ required: true, message: '请输入', trigger: 'blur' }],
        branchName: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      let opt = {
        bankName: this.bankName,
        bankCode: this.bankCode,
        page: {
          current: this.pagination.pageNum,
          size: this.pagination.pageSize,
        },
      }
      contactLineQuery(opt)
        .then((res) => {
          this.loading = false
          if (res.status == 200) {
            this.tableData = res.data.items
            if (res.data.total && opt.page.current === 1) {
              this.pagination.total = res.data.total
            }
          } else {
            this.$message({
              message: res.message,
              type: 'error',
              center: 'true',
            })
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    handleSearch() {
      this.pagination.pageNum = 1
      this.getList()
    },
    handleClear() {
      this.bankCode = ''
      this.bankName = ''
    },
    handleAdd() {
      this.titleTxt = '添加支持银行'
      this.dialogFormVisible = true
    },
    dialogSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.dialogRoleId) {
            contactLineUpdate({
              contactLine: this.dialogForm,
              originalBankCode: this.dialogRoleId,
            }).then((res) => {
              if (res.status == 200) {
                this.beforeClose()
                this.pagination.pageNum = 1
                this.getList()
                this.$message.success('编辑银行成功')
              } else {
                this.$message({
                  message: res.message,
                  type: 'error',
                  center: 'true',
                })
              }
            })
          } else {
            contactLineAdd(this.dialogForm).then((res) => {
              if (res.status == 200) {
                this.beforeClose()
                this.pagination.pageNum = 1
                this.getList()
                this.$message.success('添加银行成功')
              } else {
                this.$message({
                  message: res.message,
                  type: 'error',
                  center: 'true',
                })
              }
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    dialogClose() {
      this.beforeClose()
    },
    beforeClose() {
      this.$refs.dialogForm.resetFields()
      this.dialogForm = {
        bankName: '',
        bankCode: '',
        branchCode: '',
        branchName: '',
      }
      this.dialogFormVisible = false
      this.dialogRoleId = ''
    },
    hadnleOperate(a, b, c) {
      this.dialogRoleId = b.bankCode
      if (c.class == 'handleModify') {
        this.titleTxt = '编辑银行'
        this.dialogForm = { ...b }
        this.dialogFormVisible = true
      } else {
        this.employeeSwitch = b.enabled
        this.dialogFormVisibleStatus = true
      }
    },
    // 员工启用停用
    handleChange(val) {
      let txt = val ? '确认开启此银行？' : '确认关闭此银行？'
      this.$confirm(txt, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.saveEmployee(val)
        })
        .catch(() => {
          this.employeeSwitch = !this.employeeSwitch
        })
    },
    saveEmployee(val) {
      let opt = {
        enabled: val,
        bankCode: this.dialogRoleId,
      }
      contactLineEnableOrDisable(opt).then((res) => {
        if (res.status == 200) {
          let txt = val ? '银行已开启' : '银行已关闭'
          this.$message({
            type: 'success',
            message: txt,
          })
          this.pagination.pageNum = 1
          this.getList()
          this.dialogFormVisibleStatus = false
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            center: 'true',
          })
        }
      })
    },
    handleCurrentChange(data) {
      this.pagination.pageNum = data
      this.getList()
    },
    handleSizeChange(data) {
      this.pagination.pageSize = data
      this.getList()
    },
  },
  components: {
    cesTable,
  },
}
</script>

<style lang="stylus" scoped>
.header {
    padding:10px 20px 10px 20px;
}

.el-col {
    display: flex;
    align-items: center;
}

.label-sty {
    display: inline-block;
    width: 90px;
    flex-shrink: 0;
}

.btn-sty {
    width: 100%;
    display: flex;
    justify-content: center;
}

.employee_box {
    width: 100%;
    border: 1px solid $spColor;
    border-radius: 5px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.switch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.state-text {
    margin-top: 10px;
}

.on-text_color {
    color: rgb(59, 163, 235);
}

.off-text_color {
    color: #666;
}
</style>
