<template>
    <div>
        <div class="header">
          <el-row
              type="flex"
              justify="space-between"
              :gutter="20"
          >
              <el-col :span="6">
                  <span class="label-sty">姓名：</span>
                  <el-input
                      v-model.trim="searchName"
                      placeholder="请输入"
                      size="small"
                  ></el-input>
              </el-col>
              <el-col :span="6">
                  <span class="label-sty">账号：</span>
                  <el-input
                      v-model.trim="searchAccount"
                      placeholder="请输入"
                      size="small"
                  ></el-input>
              </el-col>
              <el-col :span="6">
                  <span class="label-sty">角色：</span>
                  <el-select
                      v-model="searchRole"
                      placeholder="请选择"
                      style="width: 100%"
                      size="small"
                  >
                      <el-option
                          v-for="item in options"
                          :key="item.reId"
                          :label="item.reName"
                          :value="item.reId"
                      >
                      </el-option>
                  </el-select>
              </el-col>
              <el-col :span="6">
                  <div class="btn-sty">
                      <el-button
                          type="primary"
                          size="small"
                          @click="handleSearch"
                      >查询</el-button>
                      <el-button
                          size="small"
                          @click="handleClear"
                      >清空</el-button>
                      <el-button
                          type="primary"
                          size="small"
                          @click="handleAdd"
                          v-perm="'/user/modifyUser'"
                      >添加新员工</el-button>
                  </div>
              </el-col>
          </el-row>
        </div>
        <cesTable
            :isButton="true"
            :isSelection='false'
            :isIndex='true'
            :isPagination='true'
            :tableData='tableData'
            :tableCols='tableCols'
            :pagination='pagination'
            :loading="loading"
            @hadnleOperate="hadnleOperate"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
        ></cesTable>

        <!-- 员工弹窗 -->
        <el-dialog
            :title="titleTxt"
            :visible.sync="dialogFormVisible"
            width="400px"
            :before-close="beforeClose"
        >
            <el-form
                :model="dialogForm"
                :rules="rules"
                ref="dialogForm"
                :label-width="formLabelWidth"
            >
                <el-form-item
                    label="员工账号"
                    prop="account"
                >
                    <el-input
                        v-model="dialogForm.account"
                        :disabled="disabled"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="员工姓名"
                    prop="name"
                >
                    <el-input v-model="dialogForm.name"></el-input>
                </el-form-item>
                <el-form-item
                    label="角色"
                    prop="role"
                >
                    <el-select
                        v-model="dialogForm.role"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.reId"
                            :label="item.reName"
                            :value="item.reId"
                        ></el-option>

                    </el-select>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="dialogClose('dialogForm')">取 消</el-button>
                <el-button
                    type="primary"
                    @click="dialogSave('dialogForm')"
                >确 定</el-button>
            </div>
        </el-dialog>

        <!-- 启用/停用弹窗 -->
        <el-dialog
            title="员工状态"
            :visible.sync="dialogFormVisibleStatus"
            width="500px"
        >
            <div class="employee_box">
                <span>员工状态</span>
                <div class="switch-content">
                    <el-switch
                        v-model="employeeSwitch"
                        @change="handleChange"
                    >
                    </el-switch>
                    <span
                        class="state-text"
                        :class="employeeSwitch === true ? 'on-text_color' : 'off-text_color'"
                    >{{employeeSwitch === true ? 'ON': 'OFF'}}</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import cesTable from "@/components/table";
import {
  getUserList,
  getRoleList,
  modifyUserList,
  userStop
} from "@/request/api";

export default {
  data() {
    return {
      searchName: "",
      searchAccount: "",
      searchRole: "",
      options: [],
      formLabelWidth: "80px",
      titleTxt: "",
      dialogFormVisible: false,
      dialogFormVisibleStatus: false,
      employeeSwitch: false,
      disabled: false,
      dialogForm: {
        account: "",
        name: "",
        role: ""
      },
      dialogRoleId: "",
      pagination: {
        pageSize: 50,
        pageNum: 1,
        total: 0
      },
      loading: false,
      tableData: [],
      tableCols: [
        {
          label: "操作",
          type: "Button",
          width: "50",
          permission: "/user/modifyUser",
          op: [
            { text: "编辑", class: "handleModify" },
            { text: "启用/停用", class: "handleOp" }
          ]
        },
        { label: "员工姓名", prop: "loginName" },
        { label: "员工账号", prop: "contractMobile" },
        { label: "角色", prop: "reName" },
        { label: "状态", prop: "stateName" }
      ],
      rules: {
        account: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
          {
            pattern: /^(1+\d{10})$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        role: [{ required: true, message: "请选择", trigger: "blur" }],
        isOpt: false,
        isOptId: "",
        isOptName: ""
      }
    };
  },
  created() {
    this.getUserInfo();
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      let opt = {
        current: this.pagination.pageNum,
        size: this.pagination.pageSize,
        contractMobile: this.searchAccount,
        loginName: this.searchName,
        rtId: this.searchRole,
        state: ""
      };
      getUserList(opt)
        .then(res => {
          this.loading = false;
          if (res.status == 200) {
            this.tableData = res.data.items;
            this.tableData.forEach(item => {
              item.stateName = item.state ? "启用" : "停用";
            });
            if (res.data.total && opt.current === 1) {
            this.pagination.total = res.data.total
          }
          } else {
            this.$message({
              message: res.message,
              type: "error",
              center: "true"
            });
          }
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },
    getUserInfo() {
      getRoleList({}).then(res => {
        if (res.status == 200) {
          this.options = res.data;
        } else {
          if (res.errorCode == "COMMON_ERROR_005") {
            this.isOpt = true;
          }
        }
      });
    },
    handleSearch() {
      this.pagination.pageNum = 1;
      this.getList();
    },
    handleClear() {
      this.searchName = "";
      this.searchAccount = "";
      this.searchRole = "";
    },
    handleAdd() {
      this.titleTxt = "添加新员工";
      this.dialogFormVisible = true;
    },
    dialogSave(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let opt = {
            contractMobile: this.dialogForm.account,
            loginName: this.dialogForm.name,
            reId: this.dialogForm.role,
            userId: this.dialogRoleId // 添加新员工不必输
          };
          modifyUserList(opt).then(res => {
            if (res.status == 200) {
              this.beforeClose();
              this.pagination.pageNum = 1;
              this.getList();
            } else {
              this.$message({
                message: res.message,
                type: "error",
                center: "true"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    dialogClose() {
      this.beforeClose();
    },
    beforeClose() {
      this.$refs.dialogForm.resetFields();
      this.dialogForm = {
        account: "",
        name: "",
        role: ""
      };
      //   this.isOpt = false;
      this.disabled = false;
      this.dialogFormVisible = false;
      this.dialogRoleId = "";
    },
    hadnleOperate(a, b, c) {
      this.dialogRoleId = b.userId;
      if (c.class == "handleModify") {
        this.titleTxt = "编辑员工";
        if (this.isOpt) {
          this.options = [
            {
              reId: b.reId,
              reName: b.reName
            }
          ];
        }
        this.dialogForm.account = b.contractMobile;
        this.dialogForm.name = b.loginName;
        this.dialogForm.role = b.reId;
        this.disabled = true;
        this.dialogFormVisible = true;
      } else {
        this.employeeSwitch = b.state;
        this.dialogFormVisibleStatus = true;
      }
    },
    // 员工启用停用
    handleChange(val) {
      let txt = val ? "是否启用此员工？" : "是否停用此员工";
      this.$confirm(txt, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.saveEmployee(val);
        })
        .catch(() => {
          this.employeeSwitch = !this.employeeSwitch;
        });
    },
    saveEmployee(val) {
      let opt = {
        state: val,
        userId: this.dialogRoleId
      };
      userStop(opt).then(res => {
        if (res.status == 200) {
          let txt = val ? "员工已启用" : "员工已停用";
          this.$message({
            type: "success",
            message: txt
          });
          this.pagination.pageNum = 1;
          this.getList();
          this.dialogFormVisibleStatus = false;
        } else {
          this.$message({
            message: res.message,
            type: "error",
            center: "true"
          });
        }
      });
    },
    handleCurrentChange(data) {
      this.pagination.pageNum = data;
      this.getList();
    },
    handleSizeChange(data) {
      this.pagination.pageSize = data;
      this.getList();
    }
  },
  components: {
    cesTable
  }
};
</script>

<style lang="stylus" scoped>
.header {
    padding:10px 20px 10px 20px;
}
// .el-row {
//     margin-bottom: 10px;
//      &:last-child {
//         margin-bottom: 0;
//     }
// }

.el-col {
    display: flex;
    align-items: center;
}

.label-sty {
    display: inline-block;
    width: 56px;
    flex-shrink: 0;
}

.btn-sty {
    width: 100%;
    display: flex;
    justify-content: center;
}

.employee_box {
    width: 100%;
    border: 1px solid $spColor;
    border-radius: 5px;
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
}

.switch-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.state-text {
    margin-top: 10px;
}

.on-text_color {
    color: rgb(59, 163, 235);
}

.off-text_color {
    color: #666;
}
</style>

